const appConfig = {
  // apiPrefix: 'https://vktestcar.5techg.com/api/',
  // apiPrefix: "https://vktestcar.5techg.com/api/",
  apiPrefix: "https://vktestcar.5techg.com/api/",
  webhookPrefix: "https://webhooks.vkrepo.in/webhooks/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
};

export default appConfig;
